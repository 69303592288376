.resultList {
    list-style: none;
    padding-left: 0;
}

/* For the top display */

.kanjiTitle {
    color: #050505;
    font-size: 3rem;
    margin: 0 0 0 1rem;
}

.resultCard {
    padding: 1rem 10vw;
}

.topDisplayText {
    color: #050505;
}

.flexContainer{
    display: flex;
}

.kanjiInfo {
    line-height: 0.5;
    font-style: italic;
    margin: 0 0 0 1rem;
}

/* For the middle display */

.onkunBox {
    display: flex;
    border-radius: 50px;
    background-image: linear-gradient(95deg, #050505 49.9%, #B0000F 50%);
    color: #F9F9F9;
    padding: 0.5rem;
    font-weight: 600;
}

.onYomiArea {
    margin: auto;
    margin-left: 1rem;
    padding: 0.5rem;
    width: 100%;
}

.kunYomiArea {
    margin: auto;
    margin-left: 1rem;
    padding: 0.5rem;
    width: 100%;
}

.onkunBoxData {
    display: inline;
}

.onKanji {
    display: none;
}

.kunKanji {
    display: none;
}

@media (min-device-width: 800px) {
    .onKanji {
        display: block;
        font-size: 2rem;
        margin: auto;
        padding: 0 1rem;
        font-weight: lighter;
        color: #8B6E26
    }

    .kunKanji {
        display: block;
        font-size: 2rem;
        margin: auto;
        padding: 0 1rem;
        font-weight: lighter;
        color: #B8860B;
    }
}

/* For the bottom display */

.translationArea {
    margin-left: 1rem;
    padding: 0.5rem;
    font-style: italic;
}