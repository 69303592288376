.header {
    background-color: #FBFBFB;
    padding: 0.5rem 0;
    border-bottom: 1px solid #E0E0E0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navLink {
    display: none;
}

.logo {
    height: 40px;
    padding: 0;
}

@media (min-device-width: 800px) {

    .flexContainer {
        justify-content: left;
        margin-left: 10vw;
    }
    
    .navLink {
        display: block;
        color: #050505;
        text-decoration: none;
        padding: 0;
        margin-left: 2rem;
    }
}