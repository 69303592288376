.pageContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.contentWrap {
    position: relative;
    overflow-x: hidden;
    flex: 1;
}