@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #FBFBFB;
  font-family: 'Source Sans Pro', sans-serif;
}

*:focus {
  outline: none;
}

.App_pageContainer__3wV3V {
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.App_contentWrap__2oJ_3 {
    position: relative;
    overflow-x: hidden;
    -webkit-flex: 1 1;
            flex: 1 1;
}
.SearchBar_searchBox__2-q5U {
    margin: 6rem auto 2rem auto;
    background-color:#FBFBFB;
    display: grid;
    grid-template-columns: 2rem 1fr;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 20px;
    width: 80vw;
    border: 2px solid #e4e4e4;
    color: #c4c4c4;
}

.SearchBar_searchIcon__3LGcx {
    padding: 0 0 0 1rem;
    height: 15px;
 }
 
.SearchBar_searchBar__3UmYf {
    border: none;
    background-color: #FBFBFB;
    padding: 0.5rem 0.5rem;
    width: 90%; /* Not perfectly center*/
    border-radius: 20px;
    font-size: 1rem;
}

.SearchBar_searchButton__36BLj {
    padding: 0.5rem 1rem;
    background-color: #E0E0E0;
    border: none;
    border-radius: 0 20px 20px 0;
    border-left: solid 2px #FBFBFB;
    color: #050505;
    display: none; /* Search bar now doesn't appear */
}

.SearchBar_searchBox__2-q5U:hover {
    color: rgb(146, 146, 146);
}

@media (min-device-width: 800px) {
    .SearchBar_searchBox__2-q5U {
        width: 60vw;
    }
}
.ResultCard_resultList__2so69 {
    list-style: none;
    padding-left: 0;
}

/* For the top display */

.ResultCard_kanjiTitle__2aE4p {
    color: #050505;
    font-size: 3rem;
    margin: 0 0 0 1rem;
}

.ResultCard_resultCard__HTzVr {
    padding: 1rem 10vw;
}

.ResultCard_topDisplayText__2PjWI {
    color: #050505;
}

.ResultCard_flexContainer__2M0mN{
    display: -webkit-flex;
    display: flex;
}

.ResultCard_kanjiInfo__2ku_b {
    line-height: 0.5;
    font-style: italic;
    margin: 0 0 0 1rem;
}

/* For the middle display */

.ResultCard_onkunBox__W7Jt7 {
    display: -webkit-flex;
    display: flex;
    border-radius: 50px;
    background-image: linear-gradient(95deg, #050505 49.9%, #B0000F 50%);
    color: #F9F9F9;
    padding: 0.5rem;
    font-weight: 600;
}

.ResultCard_onYomiArea__3XBXY {
    margin: auto;
    margin-left: 1rem;
    padding: 0.5rem;
    width: 100%;
}

.ResultCard_kunYomiArea__3hCC2 {
    margin: auto;
    margin-left: 1rem;
    padding: 0.5rem;
    width: 100%;
}

.ResultCard_onkunBoxData__2bKml {
    display: inline;
}

.ResultCard_onKanji__1DViz {
    display: none;
}

.ResultCard_kunKanji__3x9FO {
    display: none;
}

@media (min-device-width: 800px) {
    .ResultCard_onKanji__1DViz {
        display: block;
        font-size: 2rem;
        margin: auto;
        padding: 0 1rem;
        font-weight: lighter;
        color: #8B6E26
    }

    .ResultCard_kunKanji__3x9FO {
        display: block;
        font-size: 2rem;
        margin: auto;
        padding: 0 1rem;
        font-weight: lighter;
        color: #B8860B;
    }
}

/* For the bottom display */

.ResultCard_translationArea__195wL {
    margin-left: 1rem;
    padding: 0.5rem;
    font-style: italic;
}
.Results_resultsHeader__6uuFC{
    margin: 0 0 0 10vw;
    padding: 0 1rem 0 1rem;
}
.Header_header__1F7eq {
    background-color: #FBFBFB;
    padding: 0.5rem 0;
    border-bottom: 1px solid #E0E0E0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.Header_flexContainer__3_weE {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Header_navLink__fcm3I {
    display: none;
}

.Header_logo__2jpnC {
    height: 40px;
    padding: 0;
}

@media (min-device-width: 800px) {

    .Header_flexContainer__3_weE {
        -webkit-justify-content: left;
                justify-content: left;
        margin-left: 10vw;
    }
    
    .Header_navLink__fcm3I {
        display: block;
        color: #050505;
        text-decoration: none;
        padding: 0;
        margin-left: 2rem;
    }
}
.Welcome_welcomeArea__6hrue {
    margin : 0.5rem 10vw 0 10vw;
}

.Welcome_link__iR-Bz {
    color: #B0000F;
    text-decoration: none;
    font-weight: 600;
}

.Welcome_welcomeHr__z2Dxk {
    display: block;
    border: 0;
    height: 2px;
    background-image: linear-gradient(95deg, #050505 49.9%, #B0000F 50%);

}

.Welcome_bigBigText__1yZKL {
    font-size: 4rem;
}

.Welcome_bigText__1aB7o {
    font-size: 1.5rem;
}

.Welcome_bigBigText__1yZKL::after {
        content: ".";
        color: #B0000F; 
    }

.Welcome_ghostDiv__3CuUV {
    height: 1rem;
}

@media (min-device-width: 800px) {

    .Welcome_bigWelcome__p5bep {
        line-height: 5rem;
        min-height: 25rem;
    }
    
    .Welcome_bigBigText__1yZKL {
        font-size: 7rem;
    }
    
    .Welcome_bigText__1aB7o {
        font-size: 2rem;
        line-height: 4rem;
    }

    .Welcome_smallWelcome__2P0fN {
        margin-top : 3rem;
    }

    .Welcome_redCircle__2nhdv{
        height: 800px;
        width: 800px;
        background-color: #B0000F;
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 82%;
        bottom: 58%;
        z-index: -1;
    } 

    .Welcome_ghostDiv__3CuUV {
        display: none;
    } 
}


.NoResults_noResultsArea__2R17m {
    margin : 0.5rem 10vw 0 10vw;
}
.Loading_loadingArea__2YVBK {
    margin: 5rem 20vw 5rem 20vw;
    padding: 0.1rem;
    border-radius: 50px;
    color: #F9F9F9;
    background-image: linear-gradient(95deg, #050505 49.9%, #B0000F 50%);
    background-size: 250%;
    -webkit-animation: Loading_loading__NvRPI 3s ease infinite;
            animation: Loading_loading__NvRPI 3s ease infinite;
}

.Loading_mainText__1Dp3B{
    text-align: center;
}

@-webkit-keyframes Loading_loading__NvRPI {
    0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

@keyframes Loading_loading__NvRPI {
    0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}
.Footer_footerArea__247EV {
    background-color: #E0E0E0;
    color: rgb(90, 90, 90);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.Footer_copyright__1KE9D {
    margin-left: 10vw;
}
