.footerArea {
    background-color: #E0E0E0;
    color: rgb(90, 90, 90);
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.copyright {
    margin-left: 10vw;
}