.welcomeArea {
    margin : 0.5rem 10vw 0 10vw;
}

.link {
    color: #B0000F;
    text-decoration: none;
    font-weight: 600;
}

.welcomeHr {
    display: block;
    border: 0;
    height: 2px;
    background-image: linear-gradient(95deg, #050505 49.9%, #B0000F 50%);

}

.bigBigText {
    font-size: 4rem;
}

.bigText {
    font-size: 1.5rem;
}

.bigBigText::after {
        content: ".";
        color: #B0000F; 
    }

.ghostDiv {
    height: 1rem;
}

@media (min-device-width: 800px) {

    .bigWelcome {
        line-height: 5rem;
        min-height: 25rem;
    }
    
    .bigBigText {
        font-size: 7rem;
    }
    
    .bigText {
        font-size: 2rem;
        line-height: 4rem;
    }

    .smallWelcome {
        margin-top : 3rem;
    }

    .redCircle{
        height: 800px;
        width: 800px;
        background-color: #B0000F;
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 82%;
        bottom: 58%;
        z-index: -1;
    } 

    .ghostDiv {
        display: none;
    } 
}

