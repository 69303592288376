.searchBox {
    margin: 6rem auto 2rem auto;
    background-color:#FBFBFB;
    display: grid;
    grid-template-columns: 2rem 1fr;
    align-items: center;
    border-radius: 20px;
    width: 80vw;
    border: 2px solid #e4e4e4;
    color: #c4c4c4;
}

.searchIcon {
    padding: 0 0 0 1rem;
    height: 15px;
 }
 
.searchBar {
    border: none;
    background-color: #FBFBFB;
    padding: 0.5rem 0.5rem;
    width: 90%; /* Not perfectly center*/
    border-radius: 20px;
    font-size: 1rem;
}

.searchButton {
    padding: 0.5rem 1rem;
    background-color: #E0E0E0;
    border: none;
    border-radius: 0 20px 20px 0;
    border-left: solid 2px #FBFBFB;
    color: #050505;
    display: none; /* Search bar now doesn't appear */
}

.searchBox:hover {
    color: rgb(146, 146, 146);
}

@media (min-device-width: 800px) {
    .searchBox {
        width: 60vw;
    }
}